import './src/styles/global.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export { default as wrapRootElement } from './src/state/reduxWrapper';

export const onRouteUpdate = () => {
  const userLanguage = window.___gatsbyIntl?.language || 'zh';
  document.body.style.fontFamily = `${userLanguage === 'zh' ? 'HarmonyOS Sans SC' : 'MontForAnker'}, "HarmonyOSHans-Light", PingFangSC-Regular, "Microsoft YaHei", Arial, Helvetica, sans-serif`;
};
